import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../../components/layout";
import Headline from "../../components/headline";
import DynamicContent from "../../components/dynamicContent";
import { Grid, Typography, useScrollTrigger, Slide } from "@material-ui/core";
import { ContactSupportOutlined } from "@material-ui/icons";
// import useScrollTrigger from "@material-ui/core/useScrollTrigger";
const parse = require("html-react-parser");

const HideOnScroll = (props) => {
  const trigger = useScrollTrigger();
  return (
    <Slide in={!trigger}>
      <div>Hello</div>
    </Slide>
  );
};

const GenderPage = () => {
  const data = useStaticQuery(query);

  return (
    <Layout>
      <div>
        {data.allStrapiGender.nodes.map((el, idx) => (
          <div id={el.slug} key={`gender__${el.slug}-${idx}`}>
            <Headline headline={el.Headline} />

            <div>
              {el.Content.map((contentBlock) => (
                <DynamicContent block={contentBlock} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
};

const query = graphql`
  query {
    strapiHomepage {
      hero {
        title
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          publicURL
        }
      }
    }
    allStrapiGender {
      nodes {
        slug
        name
        Headline {
          heading
          id
          textField
        }
        Content {
          tiktokUrl
          __typename
          videoUrl
          textField
          quoteText
          quoteAuthor
          musicUrl
          embedCode
          instagramUrl
          heading
          eventName
          eventDescription
          eventDate
          captionText
          blockName
          captionHeading
          artistName

          SublineLink {
            authorPublication
            discoverMore
          }
          Image {
            childCloudinaryAsset {
              fluid(maxWidth: 1024) {
                ...CloudinaryAssetFluid
              }
            }
          }
          flagImage {
            name
            childCloudinaryAsset {
              fluid(maxWidth: 1024) {
                ...CloudinaryAssetFluid
              }
            }
          }
        }
      }
      edges {
        node {
          Headline {
            heading
            id
            textField
          }
        }
      }
    }

    # allStrapiArticle(filter: { status: { eq: "published" } }) {
    #   edges {
    #     node {
    #       strapiId
    #       slug
    #       title
    #       category {
    #         name
    #       }
    #       image {
    #         childImageSharp {
    #           gatsbyImageData(width: 800, height: 500)
    #         }
    #       }
    #       author {
    #         name
    #         picture {
    #           childImageSharp {
    #             gatsbyImageData(width: 30, height: 30)
    #           }
    #         }
    #       }
    #     }
    #   }
    # }
  }
`;

export default GenderPage;
